import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const { sentryDsn } = getConfig().publicRuntimeConfig;
// eslint-disable-next-line no-undef
const environment = process.env.NODE_ENV || 'production';

Sentry.init({
    dsn: sentryDsn,
    tracesSampleRate: 0.05,
    logLevel: 1,
    environment,
    // enabled: environment === 'production',
    enabled: false,
    ignoreErrors: [
        /.*failed to fetch.*/i,
    ]
});
