import { PAGE } from './pages.constants';

export const CREDIT_SELECTION_ABC_EXPERIMENT_IDS = {
    NEW_FOCUSED_FORM: 'b0a303d4-eec4-4e05',
    PROMOTION_GROUP_ID: '5a827bad-4454-4758',
    DETAILS_BUTTON_TEXT_ID: 'a182b343-7e10-4ba4',
    OFFERS_RANKING: '14dd92b7-2ff9-4253',
    PROMO_TINKOFF_BANNER: '6885c7ab-641e-4f57',
    SUITABLE_OFFERS_BANNER: 'd0fe6e72-5865-49d3',
    ADDITIONAL_QUESTIONS: 'dac1607d-dce9-4dcb',
    EXACT_CONDITIONS: '4ce819b4-ac29-4ce6',
    UTILIZATION_BANKS: '7b16860a-babf-496b',
    ARRANGE_OKB: '55a1e4c7-828d-40a2',
    NEW_MAIN_LANDING: 'c15f9e48-779e-46e5',
    SCORE_GEN7: '39d050cf-947c-483b',
    RANKING_SERVICE: 'e32e089a-01d3-4148',
} as const;

interface ExperimentConfigItem {
    /** Страницы, на которых эксперимент не активен */
    included: Array<string>;
}

/** На каких страницах эксперимент должен работать (по умолчанию на всех) */
export const experimentConfig: Partial<
    Record<ValuesOf<typeof CREDIT_SELECTION_ABC_EXPERIMENT_IDS>, ExperimentConfigItem>
> = {
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.UTILIZATION_BANKS]: {
        included: [PAGE.LANDING],
    },
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.NEW_FOCUSED_FORM]: {
        included: [PAGE.LANDING, PAGE.RESTORE],
    },
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.ARRANGE_OKB]: {
        included: [PAGE.LANDING],
    },
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.NEW_MAIN_LANDING]: {
        included: [PAGE.LANDING],
    },
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.SCORE_GEN7]: {
        included: [PAGE.LANDING],
    },
};
