import { SeoBlocks } from './seo.blocks';
import { Breadcrumbs } from './breadcrumbs';
import { PreFooter } from './prefooter';
import React from 'react';
import { useSeoBlocksHidden, useShowingSeoBlocks } from '../../../store/reducers/metadata';
import styles from './styles.module.scss';
import { useSelection } from '../../../store/reducers/selection';
import { PAGE } from '../../../constants';
import cn from 'classnames';

export const AllSeoBlocks = () => {
    const { seoBlocks, prefooter, breadcrumbs } = useShowingSeoBlocks();
    const { page } = useSelection();
    const isHidden = useSeoBlocksHidden();

    if ((!seoBlocks && !prefooter && !breadcrumbs) || isHidden) {
        return null;
    }

    const isCreditsLanding = page === PAGE.LANDING;

    return (
        <div className={cn(styles.seo_blocks_wrapper, { [styles.seo_blocks_wrapper_gray_bg]: isCreditsLanding })}>
            {seoBlocks && <SeoBlocks />}
            {breadcrumbs && <Breadcrumbs />}
            {prefooter && <PreFooter />}
        </div>
    );
};
