import { differenceInDays, differenceInYears, format as dateFormat, parse } from 'date-fns';
import { zonedTimeToUtc, getTimezoneOffset } from 'date-fns-tz';
import RuLocale from 'date-fns/locale/ru';

export const dateStringToTimestamp = (dateString: string) => {
    const dayMonthYear = dateString.split('.').map((i) => parseInt(i, 10));
    dayMonthYear[1]--;
    // @ts-ignore
    return new Date(...dayMonthYear.reverse()).getTime();
};

export const dateDiffYears = (a: Date, b: Date) => {
    return differenceInYears(a, b);
};

export const dateDiffDays = (a: Date, b: Date) => {
    return differenceInDays(a, b);
};

export const dateDiffMonths = (a: Date, b: Date) => {
    const ageDate = new Date(Math.abs(a.getTime() - b.getTime()));
    return Math.abs(ageDate.getUTCFullYear() - 1970) * 12 + ageDate.getUTCMonth();
};

export const validatePeriod = (period: unknown): period is string => {
    return typeof period === 'string' ? /P\d+[YMD]$/.test(period) : false;
};

export const periodToNumber = (
    period: string,
    value: 'days' | 'months' | 'years' = 'months',
    [daysInMonth, daysInYear] = [30, 365],
): number => {
    const years = period.match(/\d+Y/gm) ? parseInt((period.match(/\d+Y/gm) || [''])[0], 10) : 0;
    const months = period.match(/\d+M/gm) ? parseInt((period.match(/\d+M/gm) || [''])[0], 10) : 0;
    const days = period.match(/\d+D/gm) ? parseInt((period.match(/\d+D/gm) || [''])[0], 10) : 0;

    switch (value) {
        case 'days':
            return years * daysInYear + months * daysInMonth + days;

        case 'years':
            return years + Math.floor(months / 12) + Math.floor(days / daysInYear);

        default:
            return months + years * 12 + Math.floor(days / daysInMonth);
    }
};

/** 12 => 'P1Y' */
export const numberToPeriod = (value: number, period: 'd' | 'm' | 'y' = 'm') => {
    const daysValue = value * ({ d: 1, m: 30, y: 360 }[period] || 1);
    const years = Math.trunc(daysValue / 360);
    const months = Math.trunc((daysValue % 360) / 30);
    const days = (daysValue % 360) % 30;
    return 'P' + (years ? `${years}Y` : '') + (months ? `${months}M` : '') + (days ? `${days}D` : '');
};

const convertDateToUTCString = (date: Date, timezone: string) => {
    const dateWithoutTimezone = zonedTimeToUtc(date, timezone);
    return dateWithoutTimezone.toISOString();
};

export const dateStringToLocalTs = (dateString: string, dateTimeZone?: string) => {
    if (!dateString) {
        return Date.now();
    }
    const [date, time] = dateString.split(' ');
    const [day, month, year] = date.split('.').map((i) => parseInt(i, 10));
    const dateFromString = new Date(year, month - 1, day, ...time.split(':').map((i) => parseInt(i, 10)));

    return dateTimeZone
        ? new Date(convertDateToUTCString(dateFromString, dateTimeZone)).getTime()
        : dateFromString.getTime();
};

/** День недели в текстовом формате */
export const getWeekDay = (date: Date) => {
    return dateFormat(date, 'eeee', { locale: RuLocale });
};

export const dateToFormFormat = (date?: string) => {
    if (!date) {
        return undefined;
    }

    if (date.length == 10 && date.includes('.') && date.split('.')[2].length == 4) {
        return date;
    }

    if (date.length == 10 && date.includes('-') && date.split('-')[0].length == 4) {
        return date.split('-').reverse().join('.');
    }

    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth() + 1;
    return [day > 9 ? day : `0${day}`, month > 9 ? month : `0${month}`, d.getFullYear()].join('.');
};

/** Дата и месяц в текстовом формате */
export const getDateMonth = (date: Date, output = 'dd MMM') => {
    return dateFormat(date, output, { locale: RuLocale });
};

/** "14.07.2020" => 1988-07-14T00:00:00.000Z */
export const parseZonedDate = (date: string, formatString = 'dd.MM.yyyy') => {
    const localDate = parse(date, formatString, new Date());
    return new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60 * 1000);
};
