import React from 'react';
import { Footer as FooterDefault } from '@sravni/react-footer';
import { MiniFooter } from '@sravni/react-mini-footer';
import { useSelection } from 'store/reducers/selection';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../../store';
import styles from './footer.module.scss';

export const Footer: React.FC = () => {
    const { miniFooter } = useSelection();
    const settings = useSelector((state: IGlobalState) => state.siteSettings.footer);

    return (
        <div className={styles['footer-container']}>
            {miniFooter ? (
                <MiniFooter phone="8 800 707 39 02" email="help@sravni.ru" workTime={{ from: '9:00', to: '21:00' }} />
            ) : (
                <FooterDefault
                    mode="knowledge"
                    menuLinks={settings.menu}
                    socialLinks={settings.socialLinks}
                    support={settings.support}
                />
            )}
        </div>
    );
};
