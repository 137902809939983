import { FORM_FIELDS } from './form.constants';
import { ADDITIONAL_PHONE_OWNER_VALUES, EMPLOYMENT_VALUES, PURPOSE_SOURCE_VALUES } from './sources';
import { PURPOSE } from '../services/offers/offers.constants';

const COMMON_WORK_FIELDS = [
    FORM_FIELDS.ORGANIZATION_NAME,
    FORM_FIELDS.EMPLOYER_INN,
    FORM_FIELDS.EMPLOYER_TYPE,
    FORM_FIELDS.LAST_EXPERIENCE_START_MONTH,
    FORM_FIELDS.LAST_EXPERIENCE_START_YEAR,
    FORM_FIELDS.LAST_EXPERIENCE,
    FORM_FIELDS.AREA_OF_EMPLOYMENT,
    FORM_FIELDS.WORK_PHONE,
    FORM_FIELDS.WORK_ADDRESS,
    FORM_FIELDS.WORK_ROUTE,
    FORM_FIELDS.JOB_TITLE,
    FORM_FIELDS.JOB_TYPE,
    FORM_FIELDS.OKVED,
];

/** Поля, зависящие от значения FORM_FIELDS.EMPLOYMENT_TYPE */
export const EMPLOYMENT_TYPE_DEPS: Record<ValuesOf<typeof EMPLOYMENT_VALUES>, Array<string>> = {
    [EMPLOYMENT_VALUES.BUSINESS_OWNER]: COMMON_WORK_FIELDS.filter(
        (field) => ![FORM_FIELDS.JOB_TITLE, FORM_FIELDS.JOB_TYPE].includes(field),
    ),
    [EMPLOYMENT_VALUES.JUDGE]: COMMON_WORK_FIELDS,
    [EMPLOYMENT_VALUES.SERVICEMAN]: COMMON_WORK_FIELDS,
    [EMPLOYMENT_VALUES.STATE_EMPLOYEE]: COMMON_WORK_FIELDS,
    [EMPLOYMENT_VALUES.RETIRED]: [],
    [EMPLOYMENT_VALUES.NOT_WORKING_VALUE]: [FORM_FIELDS.UNEMPLOYMENT_REASON, FORM_FIELDS.ANOTHER_UNEMPLOYMENT_REASON],
    [EMPLOYMENT_VALUES.EMPLOYMENT]: COMMON_WORK_FIELDS,
};

const COMMON_PURPOSE_CAR_FIELDS = [
    FORM_FIELDS.INITIAL_FEE,
    FORM_FIELDS.AUTO_BRAND,
    FORM_FIELDS.AUTO_BRAND_ID,
    FORM_FIELDS.AUTO_MODEL,
    FORM_FIELDS.AUTO_MODEL_ID,
];

/** Поля, зависящие от значения FORM_FIELDS.PURPOSE */
export const PURPOSE_DEPS: Record<ValuesOf<typeof PURPOSE>, Array<string>> = {
    [PURPOSE_SOURCE_VALUES.NEW_CAR]: COMMON_PURPOSE_CAR_FIELDS,
    [PURPOSE_SOURCE_VALUES.USED_CAR]: [...COMMON_PURPOSE_CAR_FIELDS, FORM_FIELDS.AUTO_YEAR],
    [PURPOSE_SOURCE_VALUES.MONEY]: [],
    [PURPOSE_SOURCE_VALUES.REFINANCE_CREDIT]: [],
    [PURPOSE_SOURCE_VALUES.MORTGAGE]: [],
};

/** Поля, зависящие от значения FORM_FIELDS.ADDITIONAL_PHONE_OWNER */
export const ADDITIONAL_PHONE_OWNER_DEPS = {
    [ADDITIONAL_PHONE_OWNER_VALUES.MY_NUMBER]: [],
    [ADDITIONAL_PHONE_OWNER_VALUES.RELATIVE_NUMBER]: [FORM_FIELDS.ADDITIONAL_PHONE_OWNER_FIO],
    [ADDITIONAL_PHONE_OWNER_VALUES.FRIEND_NUMBER]: [FORM_FIELDS.ADDITIONAL_PHONE_OWNER_FIO],
};
