import { Experiments } from '../../store/reducers/abData';
import { CREDIT_SELECTION_ABC_EXPERIMENT_IDS, experimentConfig } from '../../constants';

const getAbExperimentVariant = (experiments: Experiments, name: string): string | number | boolean | undefined => {
    return experiments[name as keyof Experiments];
};

type ExperimentValueArray = Array<string | number | boolean>;
export type ExperimentValue = string | number | boolean | ExperimentValueArray;

/** В зависимости от страницы, возвращает конфигурацию экспериментов (0 для неактивных) */
export const getPageExperiments = (experiments: Experiments, page: string) => {
    return Object.entries(experiments).reduce((acc, [name, value]) => {
        const config = experimentConfig[name as keyof typeof experimentConfig];

        return Object.assign(acc, {
            [name]: !config || config.included.includes(page) ? value : '0',
        });
    }, {} as typeof experiments);
};

/**
 * Определение группы эксперимента
 * @deprecated use useExperimentGroups instead
 */
export const checkAbExperimentVariant = (
    experiments: Experiments = {},
    page: string,
    name: string,
    value: ExperimentValue,
) => {
    const pageExperiments = getPageExperiments(experiments, page);

    const experimentVariant = getAbExperimentVariant(pageExperiments, name);
    const values = ([] as ExperimentValueArray).concat(value);

    return values.includes(experimentVariant || '0');
};

/**
 * Определение группы нескольких экспериментов
 * @deprecated use useExperimentGroups instead
 */
export const checkListOfAbExperimentVariant = (
    experiments: Experiments = {},
    page: string,
    checkList: Array<{ name: string; value: ExperimentValue }>,
) => {
    return checkList.map((item) => checkAbExperimentVariant(experiments, page, item.name, item.value));
};

export const getSelectionAbExperimentAnalytics = (experiments: Experiments): string => {
    const selection_experiments = Object.values(CREDIT_SELECTION_ABC_EXPERIMENT_IDS).reduce((prev, current) => {
        if (typeof experiments[current] !== 'undefined') {
            return {
                ...prev,
                [current]: experiments[current],
            };
        }
        return prev;
    }, {} as Experiments);

    return Object.keys(selection_experiments).reduce((prev, current) => {
        const exp = `${current}.${String(selection_experiments[current as keyof Experiments])}`;
        return prev ? `${prev}|${exp}` : exp;
    }, '');
};

/** Experiments => string */
export const unwrapStatistics = (statistics: string): Experiments => {
    return statistics
        .split('|')
        .filter((v) => v)
        .reduce((acc, current) => {
            const [key, value] = current.split('.');
            return Object.assign(acc, { [key]: value });
        }, {});
};

/** string => Experiments */
export const wrapStatistics = (experiments: Experiments): string => {
    return Object.entries(experiments).reduce((acc, [key, value]) => {
        return `${acc}|${key}.${value}`;
    }, '');
};
