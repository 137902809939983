import { combineReducers, createStore as createReduxStore, Reducer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import { siteSettingsReducer } from './reducers/siteSettings';
import { userReducer } from './reducers/user';
import { locationsReducer } from './reducers/locations';
import { metadataReducer } from './reducers/metadata';
import { dictionariesReducer } from './reducers/dictionaries';
import { fbStatusesReducer } from './reducers/fbStatuses';
import { reviewsReducer } from './reducers/reviews';
import { organizationReducer } from './reducers/organization';
import { selectionReducer } from './reducers/selection';
import { popupReducer } from './reducers/popup';
import { advDataReducer } from './reducers/advData';
import { userInvitedByReducer } from './reducers/userInvitedBy';
import { mplReducer } from './reducers/mpl';
import { autoSendProductReducer } from './reducers/autoSendProduct';
import { whitelabelReducer } from './reducers/whitelabel';
import { offerReducer } from './reducers/offers';
import { marketplaceOffersReducer } from './marketplace/offers/reducer';
import { marketplaceFilterReducer } from './marketplace/filters/reducer';
import { abDataReducer } from './reducers/abData';
import { formStoreReducer } from './reducers/formStore';
import { branchesReducer } from './reducers/bankBranches';
import { esiaReducer } from './reducers/esia';
import { dialogsReducer } from './reducers/dialogs';
import { utmMarksReducer } from './reducers/utmMarks';

export interface IGlobalState {
    siteSettings: ReturnType<typeof siteSettingsReducer>;
    user: ReturnType<typeof userReducer>;
    locations: ReturnType<typeof locationsReducer>;
    metadata: ReturnType<typeof metadataReducer>;
    dictionaries: ReturnType<typeof dictionariesReducer>;
    fbStatuses: ReturnType<typeof fbStatusesReducer>;
    reviews: ReturnType<typeof reviewsReducer>;
    organization: ReturnType<typeof organizationReducer>;
    selection: ReturnType<typeof selectionReducer>;
    popup: ReturnType<typeof popupReducer>;
    advData: ReturnType<typeof advDataReducer>;
    userInvitedBy: ReturnType<typeof userInvitedByReducer>;
    mpl: ReturnType<typeof mplReducer>;
    autoSendProduct: ReturnType<typeof autoSendProductReducer>;
    whitelabel: ReturnType<typeof whitelabelReducer>;
    offers: ReturnType<typeof offerReducer>;
    marketplaceOffers: ReturnType<typeof marketplaceOffersReducer>;
    marketplaceFilter: ReturnType<typeof marketplaceFilterReducer>;
    abData: ReturnType<typeof abDataReducer>;
    formStore: ReturnType<typeof formStoreReducer>;
    branchesStore: ReturnType<typeof branchesReducer>;
    esia: ReturnType<typeof esiaReducer>;
    dialogsStack: ReturnType<typeof dialogsReducer>;
    utmMarks: ReturnType<typeof utmMarksReducer>;
}

const reducer: Reducer<IGlobalState> = combineReducers<IGlobalState>({
    siteSettings: siteSettingsReducer,
    user: userReducer,
    locations: locationsReducer,
    metadata: metadataReducer,
    dictionaries: dictionariesReducer,
    fbStatuses: fbStatusesReducer,
    reviews: reviewsReducer,
    organization: organizationReducer,
    selection: selectionReducer,
    popup: popupReducer,
    advData: advDataReducer,
    userInvitedBy: userInvitedByReducer,
    mpl: mplReducer,
    autoSendProduct: autoSendProductReducer,
    whitelabel: whitelabelReducer,
    offers: offerReducer,
    marketplaceOffers: marketplaceOffersReducer,
    marketplaceFilter: marketplaceFilterReducer,
    abData: abDataReducer,
    formStore: formStoreReducer,
    branchesStore: branchesReducer,
    esia: esiaReducer,
    dialogsStack: dialogsReducer,
    utmMarks: utmMarksReducer,
});

export const createStore = (initialState?: IGlobalState) =>
    createReduxStore(reducer, initialState, composeWithDevTools());
